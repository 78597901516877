@use 'src/shared/styles/themes/themeBW';

:root {
	--color-primary-main: #00356B;
	--color-primary-light: #0058ad;
	--color-primary-dark: #002952;
	--color-grey-50: #fafafa;
	--color-grey-100: #f5f5f5;
	--color-grey-200: #eee;
	--color-grey-300: #e0e0e0;
	--color-grey-400: #bdbdbd;
	--color-grey-500: #9e9e9e;
	--color-grey-600: #757575;
	--color-grey-700: #616161;
	--color-grey-800: #424242;
	--color-grey-900: #212121;
	--color-grey-a100: #d5d5d5;
	--color-grey-a200: #aaa;
	--color-grey-a400: #303030;
	--color-grey-a700: #616161;
	--status-good: #c0ffd3;
	--status-bad: #FFD9D9;
	--status-empty: #E5E8F2;
	--info-light: #4fc3f7;
	--info-main: #29b6f6;
	--info-dark: #0288d1;
	--error-light: #e57373;
	--error-main: #f44336;
	--error-dark: #d32f2f;
	--warning-light: #ffb74d;
	--warning-main: #ffa726;
	--warning-dark: #f57c00;
	--success-light: #81c784;
	--success-main: #66bb6a;
	--success-dark: #388e3c;
	--color-common-white: #fff;
	--color-common-black: #000;

	// * Custom
	--color-custom-main-border: #ABBDD9;
	--color-custom-main-pale: #E5EDF5;
	--color-custom-main-light: #D0DEF5;
	--color-custom-light: #57aae2;
	--color-custom-app-background: #f4f6f8;

	// * Shadows
	--shadow-0: none;
	--shadow-1: 0px 0px 20px 1px rgb(0 0 0 / 10%);
	--shadow-2: 0px 0px 20px 2px rgb(0 0 0 / 20%);
	--shadow-3: 0px 0px 20px 3px rgb(0 0 0 / 20%);
	--shadow-4: 0px 0px 20px 4px rgb(0 0 0 / 20%);
	--shadow-5: 0px 0px 20px 5px rgb(0 0 0 / 20%);
	--shadow-6: 0px 0px 20px 6px rgb(0 0 0 / 20%);
	--shadow-7: 0px 0px 20px 7px rgb(0 0 0 / 20%);
	--shadow-8: 0px 0px 20px 8px rgb(0 0 0 / 20%);
	--shadow-9: 0px 0px 20px 9px rgb(0 0 0 / 20%);
	--shadow-10: 0px 0px 20px 10px rgb(0 0 0 / 20%);
	--shadow-11: 0px 0px 20px 11px rgb(0 0 0 / 20%);
	--shadow-12: 0px 0px 20px 12px rgb(0 0 0 / 20%);
	--shadow-13: 0px 0px 20px 13px rgb(0 0 0 / 20%);
	--shadow-14: 0px 0px 20px 14px rgb(0 0 0 / 20%);
	--shadow-15: 0px 0px 20px 15px rgb(0 0 0 / 20%);
	--shadow-16: 0px 0px 20px 16px rgb(0 0 0 / 20%);
	--shadow-17: 0px 0px 20px 17px rgb(0 0 0 / 20%);
	--shadow-18: 0px 0px 20px 18px rgb(0 0 0 / 20%);
	--shadow-19: 0px 0px 20px 19px rgb(0 0 0 / 20%);
	--shadow-20: 0px 0px 20px 20px rgb(0 0 0 / 20%);
	--shadow-21: 0px 0px 20px 21px rgb(0 0 0 / 20%);
	--shadow-22: 0px 0px 20px 22px rgb(0 0 0 / 20%);
	--shadow-23: 0px 0px 20px 23px rgb(0 0 0 / 20%);
	--shadow-24: 0px 0px 20px 24px rgb(0 0 0 / 20%);

	// * Buttons
	--color-button-1: #0D3C9A;
	--color-button-2: #1075D2;
	--color-button-3: #129BF7;
	--color-button-gradient: linear-gradient(84.81deg, var(--color-button-1) 4.44%, var(--color-button-2) 25.19%, var(--color-button-3) 48.67%);
	--color-button-gradient-hover: linear-gradient(84.81deg, var(--color-button-3) 51.33%, var(--color-button-2) 74.81%, var(--color-button-1) 95.56%);

	// * layout
	--border-radius: .25rem;
	--border-radius-2: 0.625rem;

	// * Charts & ratings
	--rating-healthy: #A3E5B2;
	--rating-healthy-bg: #e8ffe8;
	--rating-balanced: #FFEAA0;
	--rating-balanced-bg: #fffbef;
	--rating-poor: #FFD2D2;
	--rating-poor-bg: #fff1f1;
	--gender-male: #ffbe80;
	--gender-female: #A3E5B2;
	--gender-other: #7f9dff;

	// * Races
	--race-middle-eastern: #A3E5B2;
	--race-hispanic: #de7a7a;
	--race-black: #FFC165;
	--race-asian: #c982f6;
	--race-caucasian: #A4C0FF;
	--race-indigenous: #ab8169;

	// * Bar indicator
	--bar-indicator-primary: #2B69F0;
	--bar-indicator-secondary: #A4C0FF;

	// * New UI
	// - primary
	--color-primary-main-new: #2B3D62;
	--color-primary-secondary-new: #90A4CE;
	--color-primary-tertiary-new: #6B91DB;

	// - traffic
	--color-traffic-red: #E77755;
	--color-traffic-orange: #E7AD55;

	// - graphs
	--graph-1: #6B7ADB;
	--graph-2: #7F5ABE;
	--graph-3: #BE5A9D;
	--graph-4: #3757BF;
	--graph-5: #1E7CBC;
	--graph-6: #6AC481;
	--graph-7: #41C3C1;
	--graph-8: #63A9BF;
}