.container {
  position: fixed;

  display: none;

  width: 100vw;

  //z-index - inline

  height: 100%;

  background-color: rgb(.5 .5 .5 / 50%);

  &_active {
    display: block;
  }

}
