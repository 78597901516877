.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
}

.buttons {
	display: flex;
	gap: 1rem;
	width: 100%;

	& > button {
		flex: 1;
	}
}