@use 'src/shared/styles/common/typography.module';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.card {
  display: flex;
  flex-direction: column;
  width: 35rem;
  padding: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.link {
  color: var(--color-primary-light);

  &:hover {
    cursor: pointer;
    color: var(--color-primary-dark);
  }
}

.list {
  margin-bottom: 1rem;
  padding-left: 2rem;
  color: var(--color-grey-500);
}

.caption {
  @include typography.caption;

  color: var(--error-main);

  &_done {
    color: var(--success-main);
  }
}
