@use 'normalize';
@use 'src/shared/styles/themes/theme';

@use 'shared/variables/dashboard';

@font-face {
  font-family: Montserrat;
  src: url("./../../fonts/Montserrat-VariableFont_wght.ttf") format('truetype');
}

:root {
	// * z-index
	--z-index-input: 100;
	--z-index-header: 200;
	--z-index-nav: 300;
	--z-index-modal: 400;
	--z-index-floating: 500;
	--z-index-notification: 600;
	--z-index-tooltip: 700;
}

*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-family: Poppins, sans-serif;
	font-size: 14px;
	line-height: 1.6;
	background-color: var(--color-custom-app-background);

	// 1280 - 1919
	@media (width <= 1919px) and (width >= 1280px) {
		font-size: 12px;
	}

	// 960 - 1279
	@media (width < 1280px) and (width >= 960px) {
		font-size: 10px;
	}

	// 600 - 959
	@media (width < 960px) and (width >= 600px) {
		font-size: 8px;
	}

	// 0 - 599
	@media (width < 600px) {
		font-size: 6px;
	}
}

body {
	overflow-x: hidden;
	width: 100vw;
}

::-webkit-scrollbar {
	width: .75rem;
	height: .75rem;
}

::-webkit-scrollbar-track {
	background: var(--color-custom-main-pale);
}

::-webkit-scrollbar-thumb {
	background: var(--color-primary-main);
	border-radius: var(--border-radius);
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 600;
	white-space: pre-wrap;
}

h1 {
	font-size: 1.6rem;
}

h2 {
	font-size: 1.4rem;
}

h3 {
	font-size: 1.2rem;
}

h4, h5, h6 {
	font-size: 1rem
}

p {
	white-space: pre-line;
}

li {
	list-style: square;

	&::marker {
		color: var(--color-primary-main)
	}
}
