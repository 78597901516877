.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.card {
	display: flex;
	flex-direction: column;
	width: 25rem;
	padding: 2rem;
}

.logo {
	& > svg {
		display: flex;
		justify-content: center;

		width: 100%;
		height: 3rem;
		margin-bottom: 2rem;
	}
}
