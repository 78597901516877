.button {
	display: flex;
	gap: .5rem;

	&_column {
		flex-direction: column;
	
		& button, a {
			width: 100%;
		}
	}
	
	&_row {
		align-items: center;
	}
}
