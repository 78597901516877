.message {
  & > p:not(:last-child) {
    margin-bottom: .5rem;
  }

  &__title {
    margin-bottom: 1rem;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
