[data-theme="bw"] {
	--color-primary-main: #363636;
  --color-primary-light: #575757;
  --color-primary-dark: #292929;
  --color-grey-50: #fafafa;
  --color-grey-100: #f5f5f5;
  --color-grey-200: #eee;
  --color-grey-300: #e0e0e0;
  --color-grey-400: #bdbdbd;
  --color-grey-500: #9e9e9e;
  --color-grey-600: #757575;
  --color-grey-700: #616161;
  --color-grey-800: #424242;
  --color-grey-900: #212121;
  --color-grey-a100: #d5d5d5;
  --color-grey-a200: #aaa;
  --color-grey-a400: #303030;
  --color-grey-a700: #616161;
  --status-good: #E7E7E7;
  --status-bad: #E4E4E4;
  --status-empty: #E8E8E8;
  --info-light: #A5A5A5;
  --info-main: #929292;
  --info-dark: #676767;
  --error-light: #959595;
  --error-main: #767676;
  --error-dark: #606060;
  --warning-light: #C0C0C0;
  --warning-main: #B3B3B3;
  --warning-dark: #929292;
  --success-light: #AAA;
  --success-main: #989898;
  --success-dark: #6B6B6B;
	--color-common-white: #fff;
	--color-common-black: #000;

		// * Custom
  --color-custom-main-border: #BABABA;
  --color-custom-main-pale: #EBEBEB;
  --color-custom-main-light: #DCDCDC;
	--color-custom-light: #979797;
  --color-custom-app-background: #F5F5F5;

  // * Shadows
  --shadow-0: none;
  --shadow-1: 0px 0px 20px 1px rgb(0 0 0 / 20%);
  --shadow-2: 0px 0px 20px 2px rgb(0 0 0 / 20%);
  --shadow-3: 0px 0px 20px 3px rgb(0 0 0 / 20%);
  --shadow-4: 0px 0px 20px 4px rgb(0 0 0 / 20%);
  --shadow-5: 0px 0px 20px 5px rgb(0 0 0 / 20%);
  --shadow-6: 0px 0px 20px 6px rgb(0 0 0 / 20%);
  --shadow-7: 0px 0px 20px 7px rgb(0 0 0 / 20%);
  --shadow-8: 0px 0px 20px 8px rgb(0 0 0 / 20%);
  --shadow-9: 0px 0px 20px 9px rgb(0 0 0 / 20%);
  --shadow-10: 0px 0px 20px 10px rgb(0 0 0 / 20%);
  --shadow-11: 0px 0px 20px 11px rgb(0 0 0 / 20%);
  --shadow-12: 0px 0px 20px 12px rgb(0 0 0 / 20%);
  --shadow-13: 0px 0px 20px 13px rgb(0 0 0 / 20%);
  --shadow-14: 0px 0px 20px 14px rgb(0 0 0 / 20%);
  --shadow-15: 0px 0px 20px 15px rgb(0 0 0 / 20%);
  --shadow-16: 0px 0px 20px 16px rgb(0 0 0 / 20%);
  --shadow-17: 0px 0px 20px 17px rgb(0 0 0 / 20%);
  --shadow-18: 0px 0px 20px 18px rgb(0 0 0 / 20%);
  --shadow-19: 0px 0px 20px 19px rgb(0 0 0 / 20%);
  --shadow-20: 0px 0px 20px 20px rgb(0 0 0 / 20%);
  --shadow-21: 0px 0px 20px 21px rgb(0 0 0 / 20%);
  --shadow-22: 0px 0px 20px 22px rgb(0 0 0 / 20%);
  --shadow-23: 0px 0px 20px 23px rgb(0 0 0 / 20%);
  --shadow-24: 0px 0px 20px 24px rgb(0 0 0 / 20%);

  // * Buttons
  --color-button-1: #393939;
  --color-button-2: #616161;
  --color-button-3: #7D7D7D;
  --color-button-gradient: linear-gradient(84.81deg, var(--color-button-1) 4.44%, var(--color-button-2) 25.19%, var(--color-button-3) 48.67%);
	--color-button-gradient-hover: linear-gradient(84.81deg, var(--color-button-3) 51.33%, var(--color-button-2) 74.81%, var(--color-button-1) 95.56%);

  // * layout
  --border-radius: .25rem;
	--border-radius-2: 0.625rem;

	// * Charts & ratings
	--rating-healthy: #CBCBCB;
	--rating-healthy-bg: #F5F5F5;
	--rating-balanced: #E8E8E8;
	--rating-balanced-bg: #FAFAFA;
	--rating-poor: #DFDFDF;
	--rating-poor-bg: #F5F5F5;
	--gender-male: #CACACA;
	--gender-female: #CBCBCB;
	--gender-other: #9E9E9E;

	// * Races
	--race-middle-eastern: #CBCBCB;
	--race-hispanic: #979797;
	--race-black: #C9C9C9;
	--race-asian: #A4A4A4;
	--race-caucasian: #BEBEBE;
	--race-indigenous: #8A8A8A;

  // * Bar indicator
  --bar-indicator-primary: #656565;
  --bar-indicator-secondary: #BEBEBE;

	// * New UI
	// - primary
--color-primary-main-new: #3B3B3B;
--color-primary-secondary-new: #909090;
--color-primary-tertiary-new: #6B6B6B;

// - traffic
--color-traffic-red: #E7E7E7;
--color-traffic-orange: #E7E7E7;

// - graphs
--graph-1: #6B6B6B;
--graph-2: #7F7F7F;
--graph-3: #BEBEBE;
--graph-4: #373737;
--graph-5: #1E1E1E;
--graph-6: #6A6A6A;
--graph-7: #414141;
--graph-8: #636363;
}