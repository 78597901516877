.form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.preloaderWrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}
